<template>
	<div class="grid">
		<template v-if="loader==true">
			<div class="col-12 mb-2 lg:col-12">
				<ProgressBar mode="indeterminate" style="height: .5em" />
			</div>
		</template>
		<template v-else>
		<template v-if="seway_exist==true">
		<div class="col-10 md:col-6 lg:col-10">
			<h2>Configuración</h2>
			<label for="email1" class="block text-900 font-medium mb-2">Generador</label>
			<div class="p-inputgroup">
				<Multiselect v-model="selectgenerador" placeholder="Seleccione Generador" :max="-1" valueProp="documento" :options="generadores" :searchable="true" label="nombre" track-by="nombre" @click="traerindicadores(selectgenerador)"/>
			</div>
		</div>
		<div class="col-2 md:col-6 lg:col-2">
			<Button type="button" class="mt-8 p-button-raised" label="Limpiar" icon="pi pi-filter-slash" :loading="loader_gen" @click="limpiar" />
			<!-- <Button type="button" class="mt-8 p-button-raised" label="Buscar" icon="pi pi-search" :loading="loader_gen" @click="cargargeneradorescustomer" /> -->
		</div>
		
		<div class="col-12 lg:col-12">




			<!-- <DataTable ref="pagination" :value="generadores_table" v-model:selection="selectedProducts" dataKey="id" :paginator="false" :rows="15" @paginate="onChangePage"
						:paginatorTemplate="'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'"
						:currentPageReportTemplate="'Showing '+pagination.from+' to '+pagination.to+' of '+pagination.total+' customers'" responsiveLayout="scroll"> -->

				<DataTable
					:value="generadores_table"
					v-model:selection="selectedProducts"
					dataKey="id"
					:paginator="true"
					:rows="rowsPerPage"
					:currentPage="currentPage"
					:totalRecords="totalRows"
					:paginatorTemplate="'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'"
					:currentPageReportTemplate="'Showing '+pagination.from+' to '+pagination.to+' of '+pagination.total+' customers'"
					responsiveLayout="scroll"
					@page="paginate($event)"
					>
				<template #header>
				</template>
				<Column field="documento" header="NIT" :sortable="false">
					<template #body="slotProps">
						<span class="p-column-title">documento</span>
						{{slotProps.data.documento}}
					</template>
				</Column>
				<Column field="razon_social" header="Generador" :sortable="false">
					<template #body="slotProps">
						<span class="p-column-title">razon_social</span>
						{{slotProps.data.razon_social}}
					</template>
				</Column>
				<Column field="generadores_seway" header="Estado" :sortable="false">
					<template #body="slotProps">
						<span class="p-column-title">Estado</span>
						<!-- {{slotProps.data.razon_social}} -->
						<template v-if="generadores_seway.find((gen_seway) => gen_seway.id_client == slotProps.data.documento)">
							<i class="pi pi-check p-3 rounded-circle lightgreen-bgcolor solid-surface-text-color" title="El generador cuenta con parámetros configurados"></i>
						</template>
						<template v-else>
							<i class="pi pi-times rounded-circle p-3 deeporange-bgcolor solid-surface-text-color" title="El generador no cuenta con parámetros configurados"></i>
						</template>
						<!-- {{ generadores_seway.find((gen_seway) => gen_seway.id_client == slotProps.data.documento) ? '<strong>aaa</strong>' : '' }} -->
					</template>
				</Column>
				<Column field="acciones" header="Opciones">
					<template #body="slotProps">
						<Button icon="pi pi-file-edit" class="p-button-rounded p-button-primary mr-2" :loading="loader_table" @click="openModalConfig(slotProps.data)" />
						<!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" /> -->
					</template>
				</Column>
				
			</DataTable>
			<!-- <div>
				<Paginator :v-model="pagination.current_page" :rows="pagination.per_page" :rowsPerPageOptions="[15]" :totalRecords="pagination.total" @page="onChangePage($event)"></Paginator>
			</div> -->
		</div>
		</template>

		<template v-else>
			<div class="col-12 lg:col-12 align-items-center text-center mt-8">
			<img src="layout/images/widgets/feature-faq.svg" alt="feature-faq" width="400">
			<div class="mx-8 my-3">
				<h4>Su empresa actualmente no cuenta con nuestra App Seway-plus<br> comunicate con un comercial para tener la aplicación móvil.</h4>
			</div>
			<Button icon="pi pi-whatsapp" label="Contactar Comercial" :loading="loader" class="p-button-rounded p-button-success" @click="refreshSaldo()" />
			</div>
		</template>
		</template>
		<Dialog v-model:visible="modal_config" :style="{width: '400px'}" header="Tipos de Despacho" :modal="true" class="p-fluid">
			<div class="field formgrid grid">
				<div class="field col-12">
					<template v-for="(tipo_despacho,index) in tipos_despachos" :key="index">
						<div class="field-checkbox">
							<Checkbox id="checkOption1" name="option" :value="valor_check(tipo_despacho)"  @click="checked_tipo_despacho(tipo_despacho,index)"  v-model="checkboxValue" />
							<label for="checkOption1">{{tipo_despacho.descripcion}}</label>
							<template v-if="tipo_despacho.check_list_seway==1">
								<label for="checkOption1">Seleccionado</label>
							</template>
						</div>
					</template>
					<!-- {{checkboxValue}} -->
				</div>
			</div>
			<template #footer>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modal_config = false"/>
				<template v-if="checkboxValue!=null">
					<Button label="Siguiente" :loading="loader_even" icon="pi pi-arrow-right" class="p-button-text" @click="showEvents"/>
				</template>
			</template>
		</Dialog>
		<Dialog v-model:visible="modal_events" :style="{width: '800px'}" header="Tipos de Despacho" :modal="true" class="p-fluid">
			<div class="field formgrid grid">
				<div class="field col-12">
					<template v-for="(evento,index) in eventos" :key="index">
						<div class="my-2">
							<DataTable ref="pagination" :value="evento.eventos" v-model:selection="selectedProducts" dataKey="id" :paginator="false" :rows="3" @paginate="onChangePage"
							:paginatorTemplate="'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'"
							:currentPageReportTemplate="'Showing '+pagination.from+' to '+pagination.to+' of '+pagination.total+' customers'" responsiveLayout="scroll">
								<template #header>
									<div class="flex flex-wrap align-items-center justify-content-between gap-1">
										<span class="text-xl text-900 font-bold">{{evento.tipo_despacho}}</span>
										<!-- <Button :loading="loader_even" class ="p-button-text  p-button-secondary" rounded raised></Button> -->
										<template v-if="evento.contain==true">
											<!-- <Button icon="pi pi-plus" class="p-button-success" title="Crear" @click="openModalEents(evento)"/> -->
											<div class="flex align-items-center">
												<template v-if="evento.editable==true">
													<Button icon="pi pi-plus" class="p-button-success" label="Crear" @click="openModalEents(evento,index)"/>
													<Button icon="pi pi-check" class="p-button-primary mx-2 px-4" label="Confirmar" @click="evento.editable = false"/>
													<Button icon="pi pi-ban" class="p-button-danger" label="Cancelar" @click="evento.editable = false"/>
												</template>
												<template v-else>
													<Button icon="pi pi-pencil" title="Editar" @click="evento.editable = true"/>
												</template>
											</div>
											
										</template>
										<template v-else>
											<Button icon="pi pi-plus" class="p-button-success" title="Crear" @click="openModalEents(evento,index)"/>
											
										</template>
										<!-- <Button icon="pi pi-refresh" rounded raised /> -->
									</div>
								</template>
								<!-- <Column field="venta_numero" header="#" :sortable="false">
									<template #body="slotProps">
										<Checkbox id="checkrangofechasolicitud" :binary="true" v-model="select_events[slotProps.index]" class="mb-3 mr-3" @click="show_parcial(slotProps.data,slotProps.index)"></Checkbox>
									</template>
								</Column> -->
								<Column field="event" header="Evento" :sortable="false">
									<template #body="slotProps">
										<span class="p-column-title">event</span>
										{{slotProps.data.event}}
									</template>
								</Column>
								<Column field="sequence" header="Secuencia" :sortable="false">
									<template #body="slotProps">
										<span class="p-column-title">sequence</span>
										{{slotProps.data.sequence}}
									</template>
								</Column>
								<Column field="photo_numbers" header="Número de Fotos" :sortable="false">
									<template #body="slotProps">
										<span class="p-column-title">photo_numbers</span>
										<!-- {{slotProps.data.photo_numbers}} -->
										<template v-if="evento.editable==false">
											{{slotProps.data.photo_numbers}}
										</template>
										<template v-else>
											<InputNumber v-model="slotProps.data.photo_numbers" :modelValue="slotProps.data.photo_numbers" inputId="minmax" :min="0" :max="100" />
										</template>
									</template>
								</Column>
								<Column field="photo_required" header="Fotos Requeridas" :sortable="false">
									<template #body="slotProps">
										<span class="p-column-title">photo_required</span>
										<template v-if="evento.editable==false">
											{{slotProps.data.photo_required}}
										</template>
										<template v-else>
											<InputNumber v-model="slotProps.data.photo_required" :modelValue="slotProps.data.photo_required" inputId="minmax" :min="0" :max="100" />
										</template>
									</template>
								</Column>
								<Column field="photo_required" header="Tiempos" :sortable="false">
									<template #body="slotProps">
										<span class="p-column-title">photo_required</span>
										<template v-if="evento.editable==false">
											{{slotProps.data.time}}
										</template>
										<template v-else>
											<InputNumber v-model="slotProps.data.time" title="Ingrese tiempo en minutos" :modelValue="slotProps.data.time" inputId="minmax" :min="0" :max="100" />
										</template>
									</template>
								</Column>
								<Column field="photo_required" header="Estado" :sortable="false">
									<template #body="slotProps">
										<span class="p-column-title">photo_required</span>
										<!-- {{slotProps.data.id}} -->
										<!-- {{slotProps.data}} -->
										<template v-if="slotProps.data.id==0">
											<Tag class="mr-2" icon="pi pi-exclamation-triangle" severity="warning" value="Sin Guardar"></Tag>
										</template>
										<template v-else>
											<Tag class="mr-2" icon="pi pi-check" severity="success" value="Guardado"></Tag>
										</template>
									</template>
								</Column>
								<template v-if="evento.editable==true">
								<Column field="sequence" header="Acciones" :sortable="false">
									<template #body="slotProps">
										<span class="p-column-title">sequence</span>
										<Button icon="pi pi-trash" title="Eliminar Evento" class="p-button-rounded p-button-danger" :loading="loader_table_event" @click="eliminar_evento(slotProps.data,slotProps.index,index)" /> 
									</template>
								</Column>
								</template>
							</DataTable>
						</div>
					</template>
				</div>
			</div>
			<template #footer>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modal_events = false"/>
				<template v-if="checkboxValue!=null">
					<Button label="Guardar" icon="pi pi-save" class="p-button-text" :loading="loader_even" @click="guardar_eventos"/>
				</template>
			</template>
		</Dialog>
		<Dialog v-model:visible="modal_eventos" :style="{width: '400px'}" header="Selecciona los eventos" :modal="true" class="p-fluid">
			<div class="field formgrid grid">
				<div class="field col-12">
					<template v-for="(evento,index) in list_events.todos_eventos" :key="index">
						<div class="field-checkbox">
							<!-- <Checkbox id="checkOption2" name="event" :value="valor_check_evento(evento)"  v-model="checkEvents" /> -->
							<Checkbox id="checkOption2" name="event" :value="evento"  v-model="checkEvents" />
							<label for="checkOption2">{{evento.event}} <br>
									</label>
						</div>
					</template>
					<!-- {{checkEvents}} -->
				</div>
			</div>
			<template #footer>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modal_eventos = false"/>
				<template v-if="checkEvents!=null">
					<Button label="Listo" :loading="loader_even" icon="pi pi-check" class="p-button-text" @click="selectedEvents"/>
				</template>
			</template>
		</Dialog>
		<Dialog v-model:visible="deleteEventDialog" :style="{width: '450px'}" header="Eliminar Evento" :modal="true">
			<div class="flex align-items-center justify-content-center">
				<i class="pi pi-exclamation-triangle mr-1" style="font-size: 2rem" />
				<ul>
					<li><p><b>Eliminar </b>Esta seguro de eliminar este evento para el tipo de despacho?</p></li>
							<!-- <li><span><b>Anular Uno </b>Anula la solicitud con solo el funcionario seleccionado</span></li> -->
				</ul>
			</div>
			<!-- <div class="flex align-items-center justify-content-center">
				<template v-if="loader==true">
					<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
				</template>
			</div> -->
			<template #footer>
				<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteEventDialog = false"/>
				<!-- <Button id="buttoneliminar" label="Eliminar Todos" icon="pi pi-check" class="p-button-text" @click="deleteCostosTodos" /> -->
				<Button id="buttoneliminar" label="Eliminar" icon="pi pi-check" :loading="loader_even" class="p-button-text" @click="deleteEvent" />
			</template>
		</Dialog>
	</div>
</template>

<script>
// import axios from 'axios'
export default {
	data() {
		return {
			message: [],
			customers: [],
			generadores_seway: [],
			tipos_despachos: [],
			list_tdespachos_selected: [],
			loader_table_event: false,
			generadores_table: [],
			username:null,
			rowsPerPage: 15, // Número de filas por página
			currentPage: 1, // Página actual
			totalRows: 0, 
			selectgenerador: null,
			seway_exist: false,
			deleteEventDialog: false,
			loader_table: false,
			modal_eventos: false,
			generadores: [],
			list_events: [],
			loader: false,
			loader_gen: false,
			loader_even: false,
			modal_config: false,
			modal_events: false,
			email:null,
			checkboxValue:null,
			checkEvents:null,
			eventos: [],
			nit_generador: '',
			select_events: [],
			paginador_mas_90: {},
			paginador_61a90: {},
			paginador_31a60: {},
			paginador_0a30: {},
			pagination: {
				current_page: 0,
				from: 0,
				last_page: 0,
				per_page: 0,
				to: 0,
				total: 0
			},
			count: 0,
			selectedProducts: [],
			items: [
					{label: 'Update', icon: 'pi pi-fw pi-refresh'},
					{label: 'Edit', icon: 'pi pi-fw pi-pencil'}
				],
			ingresoCostumer: false
		}
	},
	computed: {
		pagination() {
		const from = (this.currentPage - 1) * this.rowsPerPage + 1;
		const to = Math.min(this.currentPage * this.rowsPerPage, this.totalRows);
		return { from, to, total: this.totalRows };
		},
	},
	mounted(){
		this.loadGeneradores(1);
		// this.cargargeneradorescustomer(1);
	},
	methods:{
		paginate(event) {
			this.currentPage = event.page + 1;
			this.loadGeneradores(this.currentPage);
		},
			// Función para cargar los datos de la tabla
		loadGeneradores(page) {
		// Lógica para cargar los datos de la tabla
		this.cargargeneradorescustomer(page);

		},
		showSuccess(customer,index) {
			// document.cookie = "NameCustomer="+customer.customer.name;
			// document.cookie = "IdCustomer="+customer.customer.id;
			// document.cookie = "UrlImgCustomer="+customer.customer.url;
			// document.cookie = "IdUsuarioRol="+customer.id;
			// document.cookie = "UserAdminCustomer="+JSON.stringify(customer.useradmincustomer);


			document.cookie = "NameCustomer="+customer.name;
			document.cookie = "IdCustomer="+customer.id_customer;
			document.cookie = "UrlImgCustomer="+customer.url;
			document.cookie = "IdUsuarioRol="+customer.id;
			this.ingresoCostumer = true;
			console.log(index);
			
			// console.log(cookie[cookieName]);
			// console.log(this.$store.getters['infoNavigation/access']);
			
			this.$router.push({ path: this.$store.getters['infoNavigation/rol']+'/inicio'});
		},
		valor_check(data){
			return {
						idtipo_despachos: data.idtipo_despachos ?? 0,
						// id: data.id ?? 0,
						descripcion: data.descripcion ?? 0,
						seway_tdespacho: data.seway_tdespacho ?? 0
					}
		},
		valor_check_evento(data){
			console.log(data);
			return {
						code: data.code ?? 0,
						event: data.event ?? 0,
						id: data.id ?? 0,
						id_events: data.id_events ?? 0
					}
		},
		checked_tipo_despacho(data, index){
			console.log(data);
			console.log(index);
			console.log(this.checkboxValue);
		},
		selectedEvents(){
			console.log(this.checkEvents);
			this.modal_eventos = false;
			var i = 0
			
			this.eventos[this.list_events.index].contain = true;
			console.log(this.eventos);
			// this.eventos[this.list_events.index].editable = false;
			
			// this.eventos[this.list_events.index].eventos = [];
			this.checkEvents.forEach(element => {
				i = i+1;
				// if (condition) {
					
				// }
				var ev_seway =  this.eventos[this.list_events.index].eventos.find((event_seway) => event_seway.code == element.code)
				console.log(ev_seway);
					if (ev_seway==null) {
						this.eventos[this.list_events.index].eventos.push({
						code: element.code,
						event: element.event,
						id: element.id ?? 0,
						id_events: element.id_events,
						photo_numbers: 0,
						photo_required: 0,
						sequence: i,
						time: 0,
						types_dispatches_clients_id: 0
					});
				}
				
			});
		},
		traerindicadores(data){
			if(data){
				// console.log(data);
				this.cargargeneradorescustomer(1);
			}else{
				console.log('deseleccionado');
			}
		},
		openModalConfig(data){
			console.log(data);
			// var generador =  this.generadores_seway.find((gen_seway) => gen_seway.id_client == data.documento)
			// console.log(generador);
			// if (generador!=null) {
				const cookieName = 'Email';
				const IdRol = 'IdRol';
				this.loader_table = true;
				this.customers = [];
				this.nit_generador = '';
				this.checkboxValue = [];
				this.list_tdespachos_selected = [];
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				})
				var email = cookie[cookieName];
				var id_rol = cookie[IdRol];
				var _that = this;
                window.api.call('post', '/tipos_despachos_customers', {email: email,id_rol: id_rol,nit_generador: data.documento}).then( function(response) {
                    if(response.status == 200){
						_that.modal_config = true;
						_that.nit_generador = data.documento;
						_that.loader_table = false;
						_that.tipos_despachos = response.data.data;
						_that.list_tdespachos_selected = response.data.p_configurables;
						response.data.p_configurables.forEach(element => {
							var tipos_despachos_sele =  _that.tipos_despachos.find((gen_seway) => gen_seway.seway_tdespacho == element.code_type_dispatch)
							if (tipos_despachos_sele!=null) { 
								// _that.checkboxValue.push(element);
								_that.checkboxValue.push({
									idtipo_despachos: tipos_despachos_sele.idtipo_despachos,
									// id: element.id,
									descripcion: tipos_despachos_sele.descripcion,
									seway_tdespacho: tipos_despachos_sele.seway_tdespacho
								});
							}
						});
						_that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Se trajo los tipos despachos con exito', life: 6000 });
                    }
                }).catch(function (err) {
                    console.log(err);
					_that.loader_table = false;
                });
				
			// }else{
			// 	this.$toast.add({severity:'error', summary: "Error", detail:"Por favor llene todos los campos", life: 4000});
			// }
		},
		openModalEents(data,index){
			console.log(index);
			// var _that = this;
			this.checkEvents = [];
			this.list_events = [];
			this.modal_eventos = true;
			this.list_events = data;
			this.list_events.index = index;
			this.list_events.todos_eventos.forEach(element => {
				var eventos_sele =  this.list_events.eventos.find((evento) => evento.code == element.code)
				if (eventos_sele!=null) { 
					console.log(eventos_sele);
					// this.checkEvents.push(eventos_sele.code);
					this.checkEvents.push({
						code: eventos_sele.code,
						event: eventos_sele.event,
						// id: eventos_sele.id,
						id_events: element.id_events
					});
				}
			});
		},
		showEvents(){
			const cookieName = 'Email';
			const IdRol = 'IdRol';
			this.loader_even = true;
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			})
			var email = cookie[cookieName];
			var id_rol = cookie[IdRol];
			var _that = this;
			this.eventos = [];
			window.api.call('post', '/eventos_tipo_despachos', {email: email,id_rol: id_rol,json_tipodespachos: this.checkboxValue,nit_generador: this.nit_generador}).then( function(response) {
				if(response.status == 200){
					_that.modal_events = true;
					_that.eventos = response.data.data;
					_that.loader_even = false;
					_that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Se trajo los eventos con exito', life: 6000 });
				}
			}).catch(function (err) {
				console.log(err);
				_that.loader_even = false;
			});
		},
		guardar_eventos(){
			this.loader_even = true;
			const cookieName = 'Email';
			const IdRol = 'IdRol';
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			})
			var email = cookie[cookieName];
			var id_rol = cookie[IdRol];
			var _that = this;
			
			window.api.call('post', '/guardar_eventos', {email: email,id_rol: id_rol,eventos: this.eventos,nit_generador: this.nit_generador}).then( function(response) {
				if(response.status == 200){
					_that.modal_events = false;
					_that.modal_config = false;
					// _that.eventos = response.data.data
					_that.cargargeneradorescustomer(1);
					_that.loader_even = false;
					_that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Se Guardo los eventos con exito', life: 6000 });
				}
			}).catch(function (err) {
				console.log(err);
				_that.loader_even = false;
			});
		},
		limpiar(){
			this.selectgenerador = null;
			this.cargargeneradorescustomer(1);

		},
		eliminar_evento(data,index,index_eventos){
			console.log(data);
			console.log(index);
			console.log(index_eventos);
			
			if (data.id==0) {
				this.eventos[index_eventos].eventos.splice(index,1);
				this.$toast.add({severity:'success', summary: 'Eliminado correctamente', detail:'Los Eventos con estado Sin Guardar se eliminan sin confirmación', life: 5000});
			}else{
				localStorage.setItem('id_event',data.id);
				localStorage.setItem('idx_tipo_despacho',index);
				localStorage.setItem('idx_evento',index_eventos);
				this.deleteEventDialog = true;
				// localStorage.getItem("idx_factura_elimiada")
			}
			
		},
		deleteEvent(){
			const id_event = localStorage.getItem("id_event");
			const idx_tipo_despacho = localStorage.getItem("idx_tipo_despacho");
			const idx_evento = localStorage.getItem("idx_evento");
			// console.log(id_event);
			// console.log(idx_tipo_despacho);
			// console.log(idx_evento);
			const cookieName = 'Email';
			const IdRol = 'IdRol';
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			})
			var email = cookie[cookieName];
			var id_rol = cookie[IdRol];
			var _that = this;
			this.loader_even = true;
			window.api.call('post', '/eliminar_eventos', {email: email,id_rol: id_rol,id_event: id_event,nit_generador: this.nit_generador}).then( function(response) {
				if(response.status == 200){
					_that.eventos[idx_evento].eventos.splice(idx_tipo_despacho,1);

					// _that.modal_events = false;
					// _that.modal_config = false;
					// _that.eventos = response.data.data
					// _that.cargargeneradorescustomer(1);
					_that.deleteEventDialog = false;
					_that.loader_even = false;
					_that.$toast.add({ severity: 'success', summary: 'Eliminado correctamente', detail: 'Puedes volver agregarlo en cualquier momento', life: 6000 });
				}
			}).catch(function (err) {
				console.log(err);
				_that.deleteEventDialog = false;
				_that.loader_even = false;
			});
		},
		cleartable(){
			
			this.pagination.current_page = 0;
				this.pagination.from= 0;
				this.pagination.last_page= 0;
				this.pagination.per_page= 0;
				this.pagination.to= 0;
				this.pagination.total= 0;
		},
		onChangePage(page) {
			console.log(page);
			this.cleartable();
				this.cargargeneradorescustomer(page.page+1);
		},
		cargargeneradorescustomer(page){
			const cookieName = 'Email';
				const IdRol = 'IdRol';
				this.loader = true;
				this.customers = [];
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				})
				var email = cookie[cookieName];
				var id_rol = cookie[IdRol];
			// this.cleartable();
			console.log('page '+page);
			this.loader = true;
			var _that = this;
			this.pagination.current_page = 0;
			this.pagination.from= 0;
			this.pagination.last_page= 0;
			this.pagination.per_page= 0;
			this.pagination.to= 0;
			this.pagination.total= 0;
                window.api.call('post', '/cargar_generadores_customers?&page='+page, {email: email,id_rol: id_rol,selectgenerador: this.selectgenerador}).then( function(response) {
                    if(response.status == 200){
						_that.pagination = response.data.data;
						if (response.data.exist_seway>0) {
							_that.seway_exist = true;
						}else{
							_that.seway_exist = false;
						}
						_that.loader = false;
						
						_that.generadores = response.data.generadores;
						_that.generadores_seway = response.data.generadores_seway;
						_that.generadores_table = response.data.data.data;
						
						console.log(_that.pagination.current_page);
						console.log(_that.pagination);
							
                    }
                }).catch(function (err) {
                    console.log(err);
					_that.loader = false;
                });
		},
		salirCustomer(){
			this.ingresoCostumer = false;
		},
	},
	computed: {
			isRTL() {
				return this.$appState.RTL;
			}
		}
}
</script>

<style scoped>

</style>
